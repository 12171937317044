<template>
  <v-app :style="colorVapp">
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <!-- <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay> -->
      <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
        <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center ma-0">
              <v-avatar :color="color.theme" size="25">
                <v-icon dark size="20" @click="fn_backtomainpage()">mdi-keyboard-backspace</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pl-3 pt-1 pb-1">
                <span :style="headerPage">{{ $t("my_package.my_package") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <br>
        <v-layout>
          <v-flex d-flex justify-end v-if="this.dataAccountActive.type === 'Business'">
            <v-btn :color="color.theme" dark @click="$router.push({ path: '/packagenew/', query: { typepay: 1 } })" v-if="listpackage_current_main.length != 0 && listpackage_current_main[0].type_period === 'เดือน'" >
              {{$t("my_package.change_package")}}
            </v-btn>
          </v-flex>
          <v-flex d-flex justify-end v-else>
            <v-btn :color="color.theme" dark @click="$router.push({ path: '/packagecurrent'})">
              {{$t("my_package.change_package")}}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-list>
      <v-list nav :color="color.BG" v-else>
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="27" @click="fn_backtomainpage()">mdi-chevron-left</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="pt-1 pb-1">
              <span :style="headerPage">{{$t("my_package.my_package")}}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-flex  v-if="this.dataAccountActive.type === 'Business' ">
              <v-btn :color="color.theme" dark small @click="$router.push({ path: '/packagenew/', query: { typepay: 1 } })" v-if="listpackage_current_main.length != 0 && listpackage_current_main[0].type_period === 'เดือน'" >
                {{$t("my_package.change_package")}}
              </v-btn>
            </v-flex>
            <v-flex v-else>
              <v-btn :color="color.theme" dark small @click="$router.push({ path: '/packagecurrent'})">
                {{$t("my_package.change_package")}}
              </v-btn>
            </v-flex>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card class="elevation-0" :color="color.BG" id="thiscontainer_package" v-if="this.dataAccountActive.type === 'Business' ">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-card v-if="resolutionScreen >= 400" class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs9 lg9>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-4" cols="7">
                                  <h3>{{$t("my_package.current_mystorage")}} {{ total_storage_business }}</h3>
                                </v-col>
                                <v-col cols="4">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4>{{$t("my_package.used_space")}} {{ use_storage_business }}/{{ total_storage_business }}</h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="ml-4"></v-divider>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-4" cols="7">
                                  <h3>{{$t("my_package.total_users")}} </h3>
                                </v-col>
                                <v-col cols="4">
                                  <!-- <v-progress-linear
                                    :color="color_user"
                                    height="10"
                                    rounded
                                    :value="percentuser"
                                  ></v-progress-linear
                                  ><br /> -->
                                  <h4><v-icon color="#5a77ff">groups</v-icon>{{ use_user }} User</h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs3 lg3>
                      <v-img :src="require('@/assets/img/package2.png')" max-width="140" max-height="120" class="mt-4 mb-4 ml-7" />
                    </v-flex>
                  </v-layout>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน Business [new] -->
                <v-card v-else class="py-4" outlined id="borderradius">
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">storage</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.current_mystorage") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#E9A016">
                      <h3 class="white--text">{{ total_storage_business }}</h3>
                    </v-chip>
                  </v-list-item>
                  <v-card-text class="py-0">
                    <v-progress-linear
                      :color="color_storage"
                      height="7"
                      rounded
                      :value="percentstorage"
                    ></v-progress-linear>
                    <p class="text-left mb-2">{{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}</p>
                  </v-card-text>
                  <v-divider class="mx-4"></v-divider>
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">mdi-account-multiple</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.total_users") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#52A9FF">
                      <h3 class="white--text">{{ use_user }} User</h3>
                    </v-chip>
                  </v-list-item>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน Business -->
                <!-- <v-card v-else class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs12 lg12>
                      <v-list-item class="ml-5">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row justify-center>
                                <v-col class="ml-6" cols="12">
                                  <h3 class="text-left">
                                    {{ $t("packagenew.current_mystorage") }}
                                  </h3><br>
                                  <h3 class="text-left">
                                    {{ total_storage_business }}
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="ml-6" cols="6">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4>
                                    {{ $t("packagenew.used_space") }}
                                  </h4><br>
                                  <h4>
                                    {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                                <v-col cols="5">
                                  <v-img class="ml-3 " :src="require('@/assets/img/package2.png')" max-width="80" max-height="80" />
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="ml-4"></v-divider>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-6" cols="12">
                                  <h3>{{ $t("packagenew.total_users") }}</h3>
                                </v-col>
                                <v-col class="ml-6" cols="12">
                                  <v-progress-linear
                                    :color="color_user"
                                    height="10"
                                    rounded
                                    :value="percentuser"
                                  ></v-progress-linear
                                  ><br />
                                  <h4><v-icon color="#5a77ff">groups</v-icon>{{ use_user }} User</h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-card> -->
                <v-row>
                  <v-col class=" pa-5" cols="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length != 0">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12" v-for="(item, i) in listpackage_current_main" :key="i">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <v-layout>
                                  <!-- {{item.package_price.split(' ')[1]}} -->
                                  <!-- {{localStorage.getItem("lang")}} -->
                                  <p :style="price_">{{ numberWithCommas(item.package_price.split(' ')[0])}} {{$t("my_package.baht")}} </p>
                                  <p class="mt-1">/{{ item.type_period  === 'เดือน' ?
                                    $t("my_package.month") : $t("my_package.year") }}</p>
                                </v-layout>
                                <v-layout>
                                  <p :style="package_">{{ item.package_name }}</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ item.package_storage }}</p>
                                  <!-- <p>| {{ item.number_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{ item.period }}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length == 0 && convertstorageToByte(this.total_storage_business) <= 524288000">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <!-- <v-layout>
                                  <p :style="price_">{{ item.package_price }}</p>
                                  <p class="mt-1">/{{ item.type_period }}</p>
                                </v-layout> -->
                                <v-layout>
                                  <p :style="package_">Free</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ total_storage_business }}</p>
                                  <!-- <p>| {{ total_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                   <p>{{$t("my_package.no_expiration")}}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length == 0 && convertstorageToByte(this.total_storage_business) > 524288000">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <!-- <v-layout>
                                  <p :style="price_">{{ item.package_price }}</p>
                                  <p class="mt-1">/{{ item.type_period }}</p>
                                </v-layout> -->
                                <v-layout>
                                  <p :style="price_">Customize</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ total_storage_business }}</p>
                                  <!-- <p>| {{ total_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.no_expiration")}}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="1" align="center" justify="center" style="max-width: 2%;" v-if="listpackage_current_nextmount.length != 0">
                        <v-divider vertical></v-divider>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_nextmount.length != 0">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package_next")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12" v-for="(item, i) in listpackage_current_nextmount" :key="i">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <v-layout>
                                  <p :style="price_">{{ numberWithCommas(item.package_price.split(' ')[0]) }} {{$t("my_package.baht")}}</p>
                                  <p class="mt-1">/{{ item.type_period  === 'เดือน' ?
                                    $t("my_package.month") : $t("my_package.year") }}</p>
                                </v-layout>
                                <v-layout>
                                  <p :style="package_">{{ item.package_name }}</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ item.package_storage }}</p>
                                  <!-- <p>| {{ item.number_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{ item.get_started }}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                   <v-col class="pa-5" cols="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="4" style="flex: 0 0 100%; max-width: 100%;">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.addon_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" sm="4" v-for="(item, i) in listpackage_current_add_on" :key="i">
                            <v-card
                              class="pa-2 align-center darkmode"
                              hover
                              outlined
                              color="#ECFAFF"
                              style="border-color:#5243C2;"
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <v-layout>
                                  <v-flex xs12 lg12>
                                    <span :style="price_ + 'color:#1279BC;'">{{ numberWithCommas(item.package_price.split(' ')[0]) }} {{$t("my_package.baht")}}</span>
                                    <span class="mt-1" style="color:#1279BC;">/{{ item.type_period  === 'เดือน' ?
                                    $t("my_package.month") : $t("my_package.year") }}</span>
                                    
                                  </v-flex>
                                  <!-- <v-flex xs1 lg1>
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="blue-grey" fab x-small dark v-bind="attrs" v-on="on" @click="fn_cancel_package(item)">
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                      </template>
                                    <span>ยกเลิกแพ็กเกจ</span>
                                    </v-tooltip>
                                  </v-flex> -->
                                </v-layout>
                                <v-layout>
                                  <v-icon class="mt-n4" style="color:#1279BC;">{{
                                    item.number_user == "0" ? "mdi-package" : "mdi-account-multiple"
                                  }}</v-icon>
                                  <p style="font-weight: 600; font-size: 17px; color: black;">
                                    {{ item.number_user == "0" ? "Storage " + item.package_storage : item.number_user + " User" }}
                                  </p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p style="color: black;">{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p style="color: black;">{{ item.period }}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
       <v-card class="elevation-0" :color="color.BG" id="thiscontainer_package" v-else>
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-card v-if="resolutionScreen >= 400" class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs9 lg9>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-4 mt-6" cols="7">
                                  <h3 class="text-left mt-2">
                                    {{ $t("packagenew.current_mystorage") }}{{ total_storage_business }}
                                  </h3>
                                </v-col>
                                <v-col cols="4">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4 class="ma-2 ml-2">
                                    {{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs3 lg3>
                      <v-img :src="require('@/assets/img/package2.png')" max-width="140" max-height="120" class="mt-4 mb-4 ml-7" />
                    </v-flex>
                  </v-layout>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน Citizen [new] -->
                <v-card v-else class="py-4" outlined id="borderradius">
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">storage</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.current_mystorage") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#E9A016">
                      <h3 class="white--text">{{ total_storage_business }}</h3>
                    </v-chip>
                  </v-list-item>
                  <v-card-text class="py-0">
                    <v-progress-linear
                      :color="color_storage"
                      height="7"
                      rounded
                      :value="percentstorage"
                    ></v-progress-linear>
                    <p class="text-left mb-2">{{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}</p>
                  </v-card-text>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน Citizen -->
                <!-- <v-card v-else class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs12 lg12>
                      <v-list-item class="ml-5">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row justify-center>
                                <v-col class="ml-6" cols="12">
                                  <h3 class="text-left">
                                    {{ $t("packagenew.current_mystorage") }}
                                  </h3><br>
                                  <h3 class="text-left">
                                    {{ total_storage_business }}
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="ml-6" cols="6">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4>
                                    {{ $t("packagenew.used_space") }}
                                  </h4><br>
                                  <h4>
                                    {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                                <v-col cols="5">
                                  <v-img class="ml-3 " :src="require('@/assets/img/package2.png')" max-width="80" max-height="80" />
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-card> -->
                <v-row>
                  <v-col class=" pa-5" cols="12" md="12">
                    <v-row>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length != 0">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12" v-for="(item, i) in listpackage_current_main" :key="i">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <v-layout>
                                  <!-- {{item.package_price.split(' ')[1]}} -->
                                  <!-- {{localStorage.getItem("lang")}} -->
                                  <p :style="price_">{{ numberWithCommas(item.package_price.split(' ')[0])}} {{$t("my_package.baht")}} </p>
                                  <p class="mt-1">/{{ item.type_period  === 'เดือน' ?
                                    $t("my_package.month") : $t("my_package.year") }}</p>
                                </v-layout>
                                <v-layout>
                                  <p :style="package_">{{ item.package_name }}</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ item.package_storage }}</p>
                                  <!-- <p>| {{ item.number_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{ item.period }}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length == 0 && convertstorageToByte(this.total_storage_business) <= 524288000">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <!-- <v-layout>
                                  <p :style="price_">{{ item.package_price }}</p>
                                  <p class="mt-1">/{{ item.type_period }}</p>
                                </v-layout> -->
                                <v-layout>
                                  <p :style="package_">Free</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ total_storage_business }}</p>
                                  <!-- <p>| {{ total_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                   <p>{{$t("my_package.no_expiration")}}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_main.length == 0 && convertstorageToByte(this.total_storage_business) > 524288000">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <!-- <v-layout>
                                  <p :style="price_">{{ item.package_price }}</p>
                                  <p class="mt-1">/{{ item.type_period }}</p>
                                </v-layout> -->
                                <v-layout>
                                  <p :style="price_">Customize</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ total_storage_business }}</p>
                                  <!-- <p>| {{ total_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.no_expiration")}}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" sm="1" align="center" justify="center" style="max-width: 2%;" v-if="listpackage_current_nextmount.length != 0">
                        <v-divider vertical></v-divider>
                      </v-col>
                      <v-col cols="12" sm="4" v-if="listpackage_current_nextmount.length != 0">
                        <h3 style="fornt-size:25">
                          {{$t("my_package.main_package_next")}}
                        </h3>
                        <v-row>
                          <v-col cols="12" md="12" v-for="(item, i) in listpackage_current_nextmount" :key="i">
                            <v-card
                              class="pa-2 align-center"
                              style="background: linear-gradient(180deg, #1279BC 0%, #2D4E9A 100%);"
                              dark
                              id="borderradius"
                            >
                              <div class="pa-2">
                                <v-layout>
                                  <p :style="price_">{{ numberWithCommas(item.package_price.split(' ')[0])* item.quantity}} {{$t("my_package.baht")}}</p>
                                  <p class="mt-1">/{{ item.type_period  === 'เดือน' ?
                                    $t("my_package.month") : $t("my_package.year") }}</p>
                                </v-layout>
                                <v-layout>
                                  <p :style="package_">{{ item.package_name }}</p>
                                  <v-spacer></v-spacer>
                                  <p :style="package_">{{ item.package_storage }}</p>
                                  <!-- <p>| {{ item.number_user }} User</p> -->
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{$t("my_package.duration")}}</p>
                                </v-layout>
                                <v-layout class="mb-n3">
                                  <p>{{ item.get_started }}</p>
                                </v-layout>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";

// Import Component
import onebox_toolbar from "../components/layout/layout-toolbar-new";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_toolbar,
  },
  data: function() {
    return {
      percentstorage: 0,
      percentuser: 0,
      color_storage: "",
      color_user: "",
      total_storage_business: "",
      use_storage_business: "",
      total_user: 0,
      use_user: 0,
      isFooterDesktop: true,
      isFooterMobile: false,
      listpackage_current: [],
      listpackage_current_main: [],
      listpackage_current_add_on: [],
      listpackage_current_nextmount:[]
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    price_() {
      return "font-weight: 600; font-size:25px;";
    },
    package_() {
      return "font-weight: 600; font-size:17px;";
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onScroll() {
      const showsize = window.innerWidth;
      this.isFooterDesktop = showsize > 600;
      this.isFooterMobile = showsize < 600;
    },
    convertstorageToByte(_storage) {
      // console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      // console.log("convertstorageToByte",Math.ceil(databyte));
      return Math.ceil(databyte);
    },
    fn_getstorage_user() {
      let payload = {
        account_id: this.dataAccountId,
      };
      // console.log("payload fn_getstorage_user", payload);
      this.axios.post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_conclusion_package", payload).then((response) => {
        if (response.data.status === "OK") {
          // console.log("response+++", response);
          this.total_storage_business = response.data.result.storage_business;
          this.use_storage_business = response.data.result.used_storage_business;
          this.total_user = response.data.result.number_of_users;
          this.use_user = response.data.result.used_users;

          // คำนวณ % user
          let percentUsageUser = (this.use_user / this.total_user) * 100;
          this.percentuser = percentUsageUser === Infinity || percentUsageUser > 100 ? 100 : Math.ceil(percentUsageUser);

          // let datastorage_total = this.total_storage_business.split(" ");
          // let datastorage_use = this.use_storage_business.split(" ");
          let percentUsageStorage =
            (this.convertstorageToByte(this.use_storage_business) / this.convertstorageToByte(this.total_storage_business)) * 100;
          this.percentstorage = percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
          // console.log("percentUsageStorage",percentUsageStorage);
          // console.log("this.percentstorage", this.percentstorage);

          // สี
          this.color_user = this.percentageuser_setcolor(this.percentuser);
          this.color_storage = this.percentagestoage_setcolor(this.percentstorage);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });
    },
    percentageuser_setcolor() {
      if (this.percentuser >= 0 && this.percentuser < 51) {
        return "#5AB685";
      } else if (this.percentuser >= 51 && this.percentuser < 81) {
        return "#E9A016";
      } else if (this.percentuser >= 81) {
        return "#D1392B";
      }
    },
    percentagestoage_setcolor() {
      if (this.percentstorage >= 0 && this.percentstorage < 51) {
        return "green";
      } else if (this.percentstorage >= 51 && this.percentstorage < 81) {
        return "#ffcc00";
      } else if (this.percentstorage >= 81) {
        return "red";
      }
    },
    fn_checkpackage() {
      this.listpackage_current = [];
      this.listpackage_current_main = [];
      this.listpackage_current_add_on = [];
      this.listpackage_current_nextmount = [];
      // this.listpackage_total = [];
      // console.log('business_id', this.dataAccountActive);
      let payload = {
        // data_id: this.dataAccountId,
        data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
        type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
      };
      console.log("payload", payload);
      // console.log(this.dataBusinessProfile);
      // let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_package_current",
          payload
          //  {headers: { Authorization: auth.code } }
        )
        .then((response) => {
          // console.log("res*", response.data.result);
          if (response.data.status === "OK") {
            console.log("response", response);
            this.listpackage_current = response.data.details_package;
            this.listpackage_current_nextmount = response.data.package_nextmount
            console.log("==",this.listpackage_current_nextmount);
            for (var i = 0; i < this.listpackage_current.length; i++) {
              if (this.listpackage_current[i].type_package == "main") {
                this.listpackage_current_main.push(this.listpackage_current[i]);

              } else if (this.listpackage_current[i].type_package == "add_on") {
                this.listpackage_current_add_on.push(this.listpackage_current[i]);
              }
            }


            console.log("this.listpackage_current_main",this.listpackage_current_main);
            // for (var i = 0; i < response.data.result.length; i++) {
            //   if (response.data.result[i].status_package !== "") {
            //     let datapackage = {};
            //     datapackage["feature_extension"] = response.data.result[i].feature_extension;
            //     datapackage["package_id"] = response.data.result[i].package_id;
            //     datapackage["package_name"] = response.data.result[i].package_name;
            //     datapackage["package_price"] = response.data.result[i].package_price;
            //     datapackage["package_storage"] = response.data.result[i].package_storage;
            //     datapackage["status_package"] = response.data.result[i].status_package;
            //     this.listpackage_current.push(datapackage);
            //   }
            // }

            // for (var i = 0; i < response.data.details_package.length; i++) {
            //   if (response.data.result[i].status_package !== "") {
            //     let datatotalpackage = {};
            //     datatotalpackage["number_user"] = response.data.details_package[i].number_user;
            //     datatotalpackage["package_id"] = response.data.details_package[i].package_id;
            //     datatotalpackage["package_name"] = response.data.details_package[i].package_name;
            //     datatotalpackage["package_name_th"] = response.data.details_package[i].package_name_th;
            //     datatotalpackage["package_storage"] = response.data.details_package[i].package_storage;
            //     datatotalpackage["status_payment"] = response.data.details_package[i].status_payment;
            //     datatotalpackage["type_package"] = response.data.details_package[i].type_package;
            //     this.listpackage_total.push(datatotalpackage);
            //   }
            // }
            // console.log("this.listpackage_total", this.listpackage_total);
            // this.id_mypackage = this.listpackage_current[0].package_id;
            // console.log("++++++", this.id_mypackage);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    fn_backtomainpage() {
      if(this.dataAccountActive.type === 'Business'){
        this.$router.replace({ path: "/packagenew" });
      }else{
         this.$router.replace({ path: "/packagecurrent" });
      }
    },
    fn_cancel_package (item) {
      Swal.fire({
        title: 'ยืนยันการยกเลิกแพ็กเกจเสริม',
        text: this.$t("admintoast.confirmdeleteemail_text") + "แพ็กเกจเสริมนี้หรือไม่" + " ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("admintoast.cancel"),
        confirmButtonColor: this.color.theme,
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("admin.submit"),
      }).then((result) => {
        if (result.isConfirmed) {
          let payload = {
            account_id: this.dataAccountId,
            id: item.id,
            order_id: item.order_id,
            package_id: item.package_id,
            type_package: item.type_package
          };
          console.log('confirm', payload)
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/cancel_package",
              payload
              //  {headers: { Authorization: auth.code } }
            )
            .then((response) => {
              console.log("res*", response);
              if (response.data.status === "OK") {
                this.fn_getstorage_user()
                this.fn_checkpackage()
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    }
  },
  watch: {},
  mounted() {
    this.fn_checkpackage();
    this.fn_getstorage_user();
  },
  created() {},
};
</script>

<style>
#thiscontainer_package {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
#borderradius {
  border-radius: 15px;
}
#borderradius_card {
  border-radius: 26px;
}
</style>
